import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import qs from "qs";
import { CircularProgress, Modal } from "@mui/material";
import { formattedDate, isDateInRange } from "utils/date";
import { useTranslation } from "react-i18next";
import API from "utils/userAPI";
import ImageLoader from "utils/imageLoader";
import { IconBenefit, IconSpotify } from "images/icons/svg-components";
import CloseIcon from "@mui/icons-material/Close";
import { IconCopy } from "images/icons/svg-components";
import axios from "axios";
import { fetchUserRewardHoldings } from "containers/UserPortal/actions";
import { getFile } from "utils/s3";
import { checkMediaType } from "utils/checkMediaType";
import { ShareButton as ShareB, SpotifyButton as SpotifyB } from "./ViewQuest";
import checkicon from "images/icons/tabler_check.svg";

const ViewBenefit = ({
  benefit,
  setSelectedBenefit,
  setSelectedQuest,
  brandId,
  membershipId,
  membership,
  quests,
  loginUser,
  setExpandImage,
  setShowPaymentModal,
}) => {
  const { rewardHoldings, membershipHoldings, questHoldings, profile } =
    useSelector((state) => state.userPortal);
  const dispatch = useDispatch();
  const [benefitUnlocked, setBenefitUnlocked] = useState(true);
  const [showRedeemModal, setShowRedeemModal] = useState(false);
  const [listenSong, setListenSong] = useState(false);
  const [isRedeeming, setIsRedeeming] = useState(false);
  const { t, i18n } = useTranslation();

  const [isCopied, setIsCopied] = useState(false);
  const handleCopyClick = (text) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  const renderRedeemCode = () => {
    const holding = rewardHoldings?.filter((h) => h?.typeId === benefit?.id);
    if (holding?.length > 0) {
      let code = holding[0]?.code;
      if (code && code !== "") {
        return (
          <div className="need-wrapper">
            <span className="need-header">Your Redeem Code</span>
            <div
              className="need-item"
              style={{ justifyContent: "space-between" }}
            >
              <span className="bodyM" style={{ wordBreak: "break-all" }}>
                {code}
              </span>
              <IconCopy
                style={{
                  cursor: "pointer",
                  flexShrink: 0,
                  color: isCopied ? "#FFF" : "#1478D4",
                }}
                onClick={() => handleCopyClick(code)}
              />
            </div>
          </div>
        );
      }
    }
  };

  const onRedeem = (rewardId, userId) => {
    let isScanQRAction = false;
    if (benefit?.id == rewardId) {
      benefit?.actions?.map((action, actionIndex) => {
        const { name } = action;
        if (name === "ScanQRAction") {
          isScanQRAction = true;
        }
      });
    }

    API.post("users/redeem", {
      rewardId,
      userId,
      language: i18n?.language || "en",
      isScanQRAction: isScanQRAction,
    })
      .then((result) => {
        dispatch(fetchUserRewardHoldings());
        setIsRedeeming(false);
        if (benefit?.audio != "" && benefit?.audio != undefined) {
          setListenSong(true);
        } else {
          setShowRedeemModal(true);
        }
        dataLayer.push({
          event: "redeem_reward",
          eventCategory: "Button Click",
          eventAction: "Click",
        });
      })
      .catch((err) => {
        console.log(err);
        setIsRedeeming(false);
      });
  };

  const renderRedeemButton = () => {
    const rewardRedeemed = rewardHoldings.find(
      (o) => o.isRedeemed && o.typeId === benefit.id
    );

    const membershipHeld =
      membershipHoldings?.length > 0 &&
      membershipHoldings
        ?.map((m) => m.typeId)
        ?.some((id) => benefit?.membershipId === id);

    const inRange = isDateInRange(benefit.startDate, benefit.endDate);

    const questCompleted =
      benefit?.questId?.length === 0 ||
      (questHoldings?.length > 0 &&
        questHoldings
          ?.map((q) => q.typeId)
          ?.some((id) => benefit?.questId?.includes(id)));

    if (JSON.stringify(profile) === "{}") {
      // Not logged in
      return (
        <ClaimBanner onClick={() => loginUser()}>
          <div className="wrapper gradient-border">
            {t("UserPortal.singinbutton")}
          </div>
        </ClaimBanner>
      );
    } else if (rewardRedeemed) {
      // Already Redeemed
      return (
        <ClaimBanner>
          <span>Your Benefit has been</span>&nbsp;{" "}
          <span className="gradient">redeemed</span>
        </ClaimBanner>
      );
    } else if (!membershipHeld || !benefitUnlocked || !questCompleted) {
      return (
        <ClaimBanner disabled={true}>
          <span>Your</span>&nbsp; <span className="gradient">Benefit</span>
          &nbsp; <span>is locked</span>
        </ClaimBanner>
      );
    } else if (!inRange) {
      return (
        <ClaimBanner disabled={true}>
          <span>Your</span>&nbsp; <span className="gradient">Benefit</span>
          &nbsp; <span>is inaccessible</span>
        </ClaimBanner>
      );
    } else {
      return (
        <ClaimBanner
          onClick={() => {
            setIsRedeeming(true);
            onRedeem(benefit.id, profile?.user?._id);
          }}
        >
          {isRedeeming ? (
            <CircularProgress size={28} />
          ) : (
            <div className="wrapper gradient-border">Claim Benefit</div>
          )}
        </ClaimBanner>
      );
    }
  };

  function renderDates() {
    const today = new Date();
    const _endDate = new Date(benefit?.endDate);
    const timeDiff = _endDate.getTime() - today.getTime();
    const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    return (
      <Footer>
        <div className="benefit-endson">
          <span className="endson">Ends on</span>{" "}
          <span className="end-date">{formattedDate(benefit?.endDate)}</span>
        </div>
        {_endDate > today && (
          <div className="benefit-endson">
            <span className="endson">Expires in</span>{" "}
            <span className="end-date">{daysLeft} days</span>
          </div>
        )}
      </Footer>
    );
  }

  const renderMedia = (file) => {
    const mediaType = checkMediaType(file);
    const [fileUrl, setFileUrl] = useState("");
    useEffect(() => {
      const init = async () => {
        const url = await getFile(file);
        setFileUrl(url);
      };
      if (
        userId &&
        benefit?.audio &&
        rewardHoldings?.find((o) => o?.isRedeemed && o?.typeId === benefit?.id)
      ) {
        init();
      } else {
        setFileUrl("");
      }
    }, [userId, benefit, rewardHoldings]);
    return !fileUrl ? null : mediaType === "video" ? (
      <video
        src={fileUrl}
        style={{ maxWidth: "100%", height: "100%" }}
        controls
        controlsList="nodownload"
      ></video>
    ) : mediaType === "audio" ? (
      <audio
        controls
        controlsList="nodownload"
        src={fileUrl}
        style={{ maxWidth: "100%", background: "#1a1a22" }}
      ></audio>
    ) : (
      <img style={{ maxWidth: "100%", height: "100%" }} src={fileUrl} />
    );
  };
  const userId = localStorage.getItem("userId") ?? "";

  return (
    <MenuWrapper>
      <Modal
        open={showRedeemModal}
        onClose={() => setShowRedeemModal(false)}
        closeAfterTransition
        disableAutoFocus={true}
      >
        <ModalContentWrapper style={{ maxWidth: "620px" }}>
          <CloseIcon
            sx={{
              position: "absolute",
              top: "20px",
              right: "20px",
              width: "20px",
              height: "20px",
              color: "#7F86AD",
              cursor: "pointer",
            }}
            onClick={() => setShowRedeemModal(false)}
          />
          <div className="modal-header">
            <span className="title">{t("UserPortal.congrats")}</span>
            <span className="subtitle">
              {t("UserPortal.sendemaillabel")}{" "}
              <span className="subtitle" style={{ color: "#1478D4" }}>
                {profile?.user?.email}
              </span>
            </span>
          </div>
          {renderRedeemCode()}
          <div className="benefit">
            <div className="benefit-item">
              <span className="benefit-title">{t("UserPortal.benefit")}:</span>
              <span style={{ marginLeft: "10px" }}>{benefit?.name}</span>
            </div>
            <div
              className="wrapper-header"
              onClick={() => {
                setShowRedeemModal(false);
                setSelectedBenefit(null);
              }}
            >
              Back
            </div>
          </div>
        </ModalContentWrapper>
      </Modal>

      <Modal
        open={listenSong}
        onClose={() => setListenSong(false)}
        closeAfterTransition
        disableAutoFocus={true}
      >
        <ModalContentWrapper style={{ maxWidth: "700px" }}>
          <CloseIcon
            sx={{
              position: "absolute",
              top: "20px",
              right: "20px",
              width: "20px",
              height: "20px",
              color: "#7F86AD",
              cursor: "pointer",
            }}
            onClick={() => setListenSong(false)}
          />
          <div className="modal-header">
            <span className="title">{t("UserPortal.congrats")}</span>
            <span className="subtitle">
              To access the exclusive content, click the &quot;
              {t("UserPortal.listenasong")}&quot; button below{" "}
            </span>
          </div>
          <div className="benefit">
            <div className="benefit-item">
              <span className="benefit-title">Benefit:</span>
              <span style={{ marginLeft: "10px" }}>{benefit?.name}</span>
            </div>
            <div
              className="wrapper-header"
              onClick={async () => {
                const url = await getFile(benefit?.audio);
                setExpandImage(url);
              }}
            >
              {t("UserPortal.listenasong")}
            </div>
          </div>
        </ModalContentWrapper>
      </Modal>
      <div className="info-card">
        <ImageLoader className={"main-img"} src={benefit?.image} />
        <div className="info-section">
          <div className="title">{benefit?.name}</div>
          <IconBenefit className="benefit-icon" />
          <div className="redeem-button">{renderRedeemButton()}</div>
          {benefit?.redemptionText &&
            rewardHoldings?.find(
              (o) => o?.isRedeemed && o?.typeId === benefit?.id
            ) && (
              <div className="redemption-text">{benefit?.redemptionText}</div>
            )}
        </div>
      </div>
      <Content>
        <Title>{benefit?.name} </Title>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            width: "100%",
          }}
        >
          <div className="sub-title">Your Benefit</div>
          <Description>{benefit?.description}</Description>
        </div>

        {renderMedia(benefit?.audio)}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            width: "100%",
          }}
        >
          <div className="sub-title">How to Redeem</div>
          <Tasks>
            {(() => {
              const membershipHeld =
                membershipHoldings?.length > 0 &&
                membershipHoldings
                  ?.map((m) => m.typeId)
                  ?.some((id) => benefit?.membershipId === id);

              return (
                <div
                  className={`need-item ${membershipHeld && "gradient-border"}`}
                >
                  {t("UserPortal.join")} {membership?.name}
                  {!membershipHeld && userId && (
                    <ShareButton onClick={() => setShowPaymentModal(true)}>
                      Join
                    </ShareButton>
                  )}
                </div>
              );
            })()}
            {benefit?.requirementType === "quest" &&
            benefit?.questId?.length > 0 ? (
              (() => {
                const questCompleted =
                  questHoldings?.length > 0 &&
                  questHoldings
                    ?.map((q) => q.typeId)
                    ?.some((id) => benefit?.questId?.includes(id));
                const questNames = benefit?.questId?.map(
                  (id) => quests.filter((q) => q._id === id)[0]?.name
                );
                return (
                  <div
                    className={`need-item ${
                      questCompleted && "gradient-border"
                    }`}
                  >
                    {questNames?.length === 1
                      ? `${t("UserPortal.complete")} ${questNames[0]} ${t(
                          "UserQuests.quest"
                        )}`
                      : `${t("UserPortal.completeoneof")} ${questNames?.join(
                          ", "
                        )} ${t("UserPortal.quests")}`}
                    <div
                      style={{ marginLeft: "auto" }}
                      onClick={() => {
                        setSelectedBenefit(null);
                        setSelectedQuest(
                          quests.filter(
                            (q) => q._id === benefit?.questId?.[0]
                          )[0]
                        );
                      }}
                    >
                      <ShareButton>View</ShareButton>
                    </div>
                  </div>
                );
              })()
            ) : (
              <BenefitActions
                benefit={benefit}
                membershipId={membershipId}
                brandId={brandId}
                user={profile?.user}
                setBenefitUnlocked={setBenefitUnlocked}
              />
            )}
          </Tasks>
        </div>
        {renderRedeemCode()}
        {renderDates()}
      </Content>
    </MenuWrapper>
  );
};

function BenefitActions({
  benefit,
  user,
  membershipId,
  brandId,
  setBenefitUnlocked,
}) {
  const { t } = useTranslation();
  const [actionResults, setActionResults] = useState([]);
  const [userRank, setUserRank] = useState();
  const userId = localStorage.getItem("userId") ?? "";
  useEffect(() => {
    async function fetchData() {
      if (userId && userId !== "") {
        const ipAddress = await axios
          .get("https://api.ipify.org?format=json")
          .catch((e) => console.log(e));

        const rewardAction = await API.get(
          `users/rewardActionsData/${benefit?._id}?qrId=${localStorage.getItem(
            "qr"
          )}&ip=${ipAddress?.data?.ip}`
        );
        setActionResults(rewardAction.data?.data);
      }
      // if (userId && brandId) {
      //   const userRank = await API.get(`/spotify/currentUserRank/${brandId}`);
      //   setUserRank(userRank?.data);
      // }
    }

    fetchData();
  }, [benefit]);

  const SaveSpotifyButton = ({ spotifyLink }) => {
    const [loadingSpotify, setLoadingSpotify] = useState(false);
    const scopes =
      "user-read-email user-read-private user-read-recently-played user-library-read user-top-read user-library-modify playlist-read-private playlist-modify-private playlist-modify-public user-follow-modify user-follow-read";
    return user?.spotifyId === undefined ||
      user?.spotifyId === "" ||
      user?.reAuthSpotify ? (
      <div style={{ marginLeft: "auto" }}>
        <SpotifyButton
          onClick={() => {
            dataLayer.push({
              event: "click_spotify",
              eventCategory: "Button Click",
              eventAction: "Click",
            });

            window.location.href =
              "https://accounts.spotify.com/authorize?" +
              qs.stringify({
                response_type: "code",
                client_id: process.env.REACT_APP_SPOTIFY_CLIENT_ID,
                scope: scopes,
                redirect_uri: `${process.env.REACT_APP_API_URL}/api/v1/spotify/callback`,
                state: JSON.stringify({
                  brandId: brandId,
                  spotifyLink: spotifyLink,
                  membershipId: membershipId,
                  id: userId,
                  rewardId: benefit.id,
                }),
              });
          }}
          variant="contained"
          disableRipple
        >
          <IconSpotify className="spotify-icon" />
          {t("UserPortal.authenticate")}
        </SpotifyButton>
      </div>
    ) : (
      <div style={{ marginLeft: "auto" }}>
        {
          <>
            {loadingSpotify ? (
              <CircularProgress style={{ color: "#1ed760" }} />
            ) : (
              <SpotifyButton
                onClick={async () => {
                  setLoadingSpotify(true);
                  try {
                    await API.post("/spotify/saveSpotifyLink", {
                      spotifyLink: spotifyLink,
                    });
                    const rewardAction = await API.get(
                      `users/rewardActionsData/${benefit.id}`
                    );
                    setActionResults(rewardAction.data?.data);
                    setLoadingSpotify(false);
                  } catch (e) {
                    setLoadingSpotify(false);
                    console.log(e);
                  }
                }}
                variant="contained"
                disableRipple
              >
                <IconSpotify className="spotify-icon" />
                {t("UserPortal.save")}
              </SpotifyButton>
            )}
          </>
        }
      </div>
    );
  };

  function renderActions(actions, actionResults) {
    let benefitUnlocked = true;
    const actionsDiv = actions.map((action, actionIndex) => {
      const { name, trackName, count, minutes, spotifyData } = action;
      if (name === "SpotifyLinkSaveAction") {
        const check = actionResults[actionIndex];
        benefitUnlocked = benefitUnlocked && check;
        return (
          <div className={`need-item ${check && "gradient-border"}`}>
            {t("UserPortal.save") +
              ` "` +
              spotifyData?.name +
              `"` +
              t("UserPortal.onspotify")}
            {!check && userId && (
              <SaveSpotifyButton
                spotifyLink={
                  "https://open.spotify.com/" +
                  spotifyData?.type +
                  "/" +
                  spotifyData?.id
                }
              />
            )}
          </div>
        );
      } else if (name === "SpotifyStreamAction") {
        const check = actionResults[actionIndex] > count;
        benefitUnlocked = benefitUnlocked && check;
        return (
          <div className={`need-item ${check && "gradient-border"}`}>
            {t("NewCampaign.listen") +
              ` "` +
              trackName +
              `" ` +
              count +
              " " +
              t("NewCampaign.time") +
              t("UserPortal.onspotify")}
          </div>
        );
      } else if (name === "SpotifyMinutesAction") {
        const check = actionResults[actionIndex] > minutes;
        benefitUnlocked = benefitUnlocked && check;
        return (
          <div className={`need-item ${check && "gradient-border"}`}>
            {t("NewCampaign.listen") +
              ` "` +
              trackName +
              `" ` +
              minutes +
              " " +
              t("NewCampaign.minutes") +
              t("UserPortal.onspotify")}
          </div>
        );
      } else if (name === "SpotifySongSaveAction") {
        const check = actionResults[actionIndex];
        benefitUnlocked = benefitUnlocked && check;
        return (
          <div className={`need-item ${check && "gradient-border"}`}>
            {t("UserPortal.save") +
              ` "` +
              trackName +
              `"` +
              t("UserPortal.onspotify")}
            {!check && userId && (
              <SaveSpotifyButton
                spotifyLink={
                  "https://open.spotify.com/track/" + action?.trackId
                }
              />
            )}
          </div>
        );
      } else if (name === "ScanQRAction") {
        const check = localStorage.getItem("qr") === membershipId;
        benefitUnlocked = benefitUnlocked && check;
        return (
          <div className={`need-item ${check && "gradient-border"}`}>
            {t("UserPortal.scanqrcode")} {" - "} {QRText}
          </div>
        );
      } else if (name === "location") {
        const check = actionResults[actionIndex];
        benefitUnlocked = benefitUnlocked && check;
        return (
          <div className={`need-item ${check && "gradient-border"}`}>
            you are in {action?.country}, {action?.city}
          </div>
        );
      }
    });
    setBenefitUnlocked(benefitUnlocked);
    return actionsDiv;
  }

  function renderLeaderboard(leaderboard) {
    let benefitUnlocked = true;
    const leaderboards = leaderboard.map((entry, index) => {
      let check = new Date() > new Date(entry?.endDate);
      if (entry?.platformName === "Spotify") {
        check =
          check &&
          userRank?.leaderBoardData?.[0]?.index >= entry.from &&
          userRank?.leaderBoardData?.[0]?.index <= entry.to;
      } else if (entry?.platformName === "Shopify") {
        check =
          check &&
          userRank?.shopifyRank >= entry.from &&
          userRank?.shopifyRank <= entry.to;
      }
      benefitUnlocked = benefitUnlocked && check;
      return (
        <div className={`need-item ${check && "gradient-border"}`}>
          {entry?.platformName} {t("UserPortal.leaderboardrankbetween")}
          {entry.from} - {entry.to}
        </div>
      );
    });
    setBenefitUnlocked(benefitUnlocked);
    return leaderboards;
  }

  return (
    <>
      {benefit?.actions?.length > 0 &&
        renderActions(benefit?.actions, actionResults)}
      {benefit?.leaderboard?.length > 0 &&
        renderLeaderboard(benefit?.leaderboard)}
    </>
  );
}
const Footer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 33px;
  .benefit-endson {
    display: flex;
    align-items: flex-start;
    gap: 5px;
    opacity: 0.9;
    font-family: Satoshi;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 100% */
    color: #fff;
    .end-date {
      font-weight: 900;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const Tasks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  .need-item.gradient-border:after {
    content: url(${checkicon});
    display: inline-block;
  }
  .need-item {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    gap: 12px;
    border-radius: 10px;
    background: linear-gradient(
      180deg,
      rgba(32, 31, 31, 0.6) 0%,
      rgba(41, 41, 41, 0.6) 100%
    );

    color: #fff;
    font-family: Satoshi;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 100% */
    &.disabled {
      color: ${(props) => props.theme.palette.lightGrey};
    }

    a {
      color: inherit;
      word-break: break-word;
    }
  }
`;

export const Description = styled.div`
  align-self: stretch;
  color: #fff;
  font-family: Satoshi;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 125% */
  opacity: 0.9;
  white-space: pre-wrap;

  @media (max-width: 768px) {
    ${(props) => props.theme.typography.paragraph1};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
  width: 100%;
  @media (max-width: 768px) {
    gap: 35px;
    width: 100%;
  }
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  color: #fff;
  font-family: Satoshi;
  font-size: 33.18px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 96.444% */
  @media (max-width: 768px) {
    ${(props) => props.theme.typography.h5Bold};
    line-height: 24px;
  }
`;
export const MenuWrapper = styled.div`
  z-index: 1;
  display: flex;
  gap: 40px;
  width: 100%;
  margin-top: 98px;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 40px;
  @media (max-width: 768px) {
    margin-top: 0px;
    padding: 0 18px;
  }
  .sub-title {
    color: #fff;
    font-family: Satoshi;
    font-size: 23.04px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 69.444% */
    opacity: 0.9;
    @media (max-width: 768px) {
      font-size: 20px;
    }
  }
  .info-card {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: #1b1b1b;
    filter: drop-shadow(0px 2.707px 13.536px rgba(0, 0, 0, 0.2));
    backdrop-filter: blur(12px);
    border-radius: 12px;
    .redemption-text {
      max-width: 234px;
      white-space: normal;
      word-break: break-word;
      color: #fff;
      text-align: center;
      ${(props) => props.theme.typography.paragraph1};
    }
    @media (max-width: 768px) {
      padding: 16px;
      gap: 17px;
      .redemption-text {
        max-width: 202px;
      }
    }
  }
  .info-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 21px;
    .title {
      text-align: center;
      color: #fff;
      max-width: 234px;
      white-space: normal;
      word-break: break-word;
      ${(props) => props.theme.typography.h6};
      line-height: 20px;
    }
    @media (max-width: 768px) {
      gap: 18px;
      .title {
        max-width: 202px;
        font-size: 17px;
        line-height: 17px;
      }
      .benefit-icon {
        width: 21px;
        height: 22px;
      }
    }
  }
  .main-img,
  .main-img img {
    width: 237px;
    height: 237px;
    object-fit: cover;
    border-radius: 12px;
    @media (max-width: 768px) {
      width: 202px;
      height: 205px;
    }
  }
  .mobile-item {
    display: none;
  }
  .desktop-quest-item {
    display: flex;
  }
  .gradient-border {
    background: linear-gradient(#1b1b1b 0 0) padding-box,
      linear-gradient(90deg, #00ffd3, #1478d4, #00ffd3) border-box !important;
    border: 4px solid transparent;
    border-radius: 11px;
    @media (max-width: 768px) {
      border: solid 3.3px transparent;
      border-radius: 9px;
    }
  }
  @media (max-width: 768px) {
    height: 100%;
    flex-direction: column;
    align-items: center;
    .redeem-button {
      display: flex;
    }
    .desktop-item {
      display: none;
    }
    .mobile-item {
      display: flex;
    }
  }
`;
export const ClaimBanner = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  ${(props) => props.theme.typography.paragraph1};

  .wrapper {
    border-radius: 7px;
    background: #1b1b1b;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  @media (max-width: 768px) {
    max-width: 202px;
    display: inline;
    text-align: center;
  }
  .gradient {
    background: linear-gradient(90deg, #00ffd3 67.87%, #1478d4 99.99%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 20px 40px 20px;
  gap: 28px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  border-radius: 16px;
  background: #1b1b1b;

  .wrapper-header {
    ${(props) => props.theme.typography.paragraph2Bold};
    background: ${(props) => props.theme.palette.surfaceBlue};
    color: #fff;
    width: 100%;
    text-align: center;
    padding: 12px 20px;
    border-radius: 6px;
    cursor: pointer;
  }

  .modal-header {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title {
      text-align: center;
      color: #fff;
      ${(props) => props.theme.typography.h4Bold};
      @media (max-width: 768px) {
        ${(props) => props.theme.typography.h6Bold};
        line-height: 24px;
      }
    }
    .subtitle {
      font-family: Satoshi;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      text-align: center;
      color: #747474;
    }
  }

  .benefit {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    .benefit-title {
      text-align: center;
      color: #fff;
      ${(props) => props.theme.typography.h6Bold};
      @media (max-width: 768px) {
        ${(props) => props.theme.typography.paragraph2Bold};
      }
    }
    .benefit-item {
      display: flex;
      justify-content: center;
      gap: 8px;
      ${(props) => props.theme.typography.h6};

      text-align: center;
      color: ${(props) => props.theme.palette.surfaceBlue};
      padding: 20px 12px;
      background: linear-gradient(
        180deg,
        rgba(32, 31, 31, 0.6) 0%,
        rgba(41, 41, 41, 0.6) 100%
      );
      border: 1px solid;
      border-color: ${(props) => props.theme.palette.surfaceBlue};
      border-radius: 6px;
      width: 100%;

      span {
        display: flex;
      }
    }
  }

  .need-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    @media (max-width: 768px) {
      gap: 12px;
    }
    .need-header {
      ${(props) => props.theme.typography.h6};
      color: #fff;
      align-self: left;
      @media (max-width: 768px) {
        ${(props) => props.theme.typography.bodySMedium};
        line-height: normal;
      }
    }
    .need-item {
      display: flex;
      align-items: center;
      padding: 10px 20px;
      gap: 12px;
      background: linear-gradient(
        180deg,
        rgba(32, 31, 31, 0.6) 0%,
        rgba(41, 41, 41, 0.6) 100%
      );

      ${(props) => props.theme.typography.bodyM};
      color: #fff;
      &.disabled {
        color: ${(props) => props.theme.palette.lightGrey};
      }

      a {
        color: inherit;
      }

      @media (max-width: 768px) {
        ${(props) => props.theme.typography.bodyS};
        line-height: normal;
      }
    }
  }
`;

const ShareButton = styled(ShareB)({
  marginLeft: "auto",
});

const SpotifyButton = styled(SpotifyB)({
  marginLeft: "auto",
});

export default ViewBenefit;
